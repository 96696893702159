
import { Button } from 'antd';
import React, { PureComponent } from 'react';

import {
    DownloadOutlined,
    LeftOutlined,
    SettingFilled,
    SmileOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import "./index.less"


type BButtionProps = {
    children: React.ReactNode
    icon?: React.ReactNode
    type?: "primary" | "danger"
    size?: "default" | "large"
    hoverChange?:boolean
    style?: React.CSSProperties
    onClick?: () => void
}
type State = {
}
export class BButtion extends PureComponent<BButtionProps, State> {
    constructor(props: BButtionProps) {
        super(props)
        this.state = {
        }
    }

    render() {
        const type = this.props.type || "primary"
        const size = this.props.size || "default"
        const className = ["BButtion"]
        if(this.props.hoverChange){
            className.push("hoverChange")
        }
        return <Button className={className.join(" ")}
            onClick={this.props.onClick}
            shape="round" icon={this.props.icon} size={"large"} style={{
                background: type === "primary" ? `linear-gradient(173.46deg, #7B94FE 2.95%, #9281FC 83.98%)` : `linear-gradient(173.46deg, #FF989B 2.95%, #FD7199 84%)`,
                boxShadow: type === "primary" ? `0px 24px 26px rgba(103, 50, 255, 0.179904)` : `0px 24px 26px rgba(253, 113, 153, 0.180643)`,
                height: size === "large" ? 60 : 42,
                minWidth: 125,
                padding: 0,
                borderColor: "transparent",
                color: "white",
                lineHeight: "18px",
                fontSize: 15,
                ...this.props.style
            }}>
            {this.props.children}
        </Button>

    }
    componentDidMount(): void {

    }

}


