import { Button, message } from 'antd';



/**文件网络请求 */
export const ResFetchRaw = async (inputURL: URL) => {
    const response = await fetch(inputURL)
    const arrayBuffer = await response.arrayBuffer()

    const contentType = response.headers.get("content-type") || "application/octet‑stream"
    const contentDate = response.headers.get("date") || new Date(0)
    const contentExpires = response.headers.get("expires") || new Date(0)

    const contentSize = arrayBuffer.byteLength

    const url = new URL(response.url)
    return { url , arrayBuffer, contentType,contentDate,contentExpires,contentSize}
}

/**文件网络请求，包含blob地址 */
export const ResFetch2URL = async (url: URL) => {
    const response = await ResFetchRaw(url)
    let blob = new Blob([response.arrayBuffer],{
        type:response.contentType
    });
    let blobUrl = new URL(URL.createObjectURL(blob))
    return {
        ...response,
        blobUrl,
    }
};

/**图片文件(网络)请求，包含图片大小信息 */
export const getImageInfo = (url: URL): Promise<{
    height: number
    width: number
}> => {
    return new Promise((resolve, reject) => {
        var img = new Image();
        img.onload = function () {
            resolve({
                height: img.height,
                width: img.width,
            })
        };
        img.onerror = (event) => {
            reject(event)
        }
        setTimeout(() => {
            reject("timeout")
        }, 15000);
        img.src = url.href
    })
};
