import { getImageInfo } from "@/function/Network";
import { ResFetch2URL } from "@/function/Network";
import React from "react";
import { PureComponent } from "react";
import "./index.less"

type RBackgroundConfig = { urlPreFix?: string }
export const BackgroundConfig = React.createContext<RBackgroundConfig>({});


type SelectorProps = {
    onClick: (avatarURL: URL) => void
}
export class Selector extends PureComponent<SelectorProps> {
    constructor(props: SelectorProps) {
        super(props);
    }
    render() {
        return <div className="Selector" style={{
            padding: "5%",
            paddingTop: "8%",
            paddingBottom: "8%",
        }}>
            {Array.from(new Array(8), (item, id) => {
                const key = id + 1
                const url = this.toURL(`/挂饰/res/${key}.png`)
                return <div key={id} className="Selection" style={{
                    display: "inline-block",
                    width: "44%",
                    margin: "3%",
                    cursor: "pointer",
                }} onClick={() => { this.props.onClick(url) }}>
                    <img src={url.href} style={{
                        width: "100%",
                    }} />
                </div>
            })}
        </div>
    }
    toURL(path: string) {
        const url = new URL(document.location.href)
        const splitedPath = url.pathname.split("/")
        if ([...splitedPath].pop() === "") {
            splitedPath.pop()
        }
        url.pathname = `${splitedPath.join("/")}${path}`
        
        return url
    }

}
