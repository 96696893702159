
import { Slider } from 'antd';
import html2canvas from 'html2canvas';
import React, { PureComponent } from 'react';
import { Background } from '../Background';
import {
    MinusOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { BButtion } from '@/component/button';
import { ResFetch2URL } from '@/function/Network';
import "./index.less";


type EditorPageProps = {
    children?: React.ReactNode
    uploadedImage?: {
        url: URL,
        width: number
        height: number
    }
    selectedURL?: URL
    onBack: () => void
    onDone: (image: {
        blobURL: URL
        b64: string
    }) => void
}
type State = {
    imageURL?: URL
    scale?: number
    defaultScaleValue?: number
    moving?: {
        startScreenX: number
        startScreenY: number
    }
    movingOffsetX: number
    movingOffsetY: number
    offsetX: number
    offsetY: number
}
export class EditorPage extends PureComponent<EditorPageProps, State> {
    drawerBoxRef: React.RefObject<HTMLDivElement>;
    constructor(props: EditorPageProps) {
        super(props)
        this.drawerBoxRef = React.createRef();

        this.state = {
            movingOffsetX: 0,
            movingOffsetY: 0,
            offsetX: 0,
            offsetY: 0,
        }
    }

    render() {
        return <Background style={{ height: "100%" }} shadow>
            <div className='EditorPage' style={{
                height: "100%",
                width: "100%",
            }}>
                <div style={{ height: "5vh", display: "flex", alignItems: "center" }} />
                <div style={{
                    marginLeft: 15,
                    width: `calc(100% - ${15 * 2}px)`,
                    borderRadius: 20,
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: 32,
                    paddingTop: "5vh",
                }}>
                    <div
                        ref={this.drawerBoxRef}
                        className='drawerBox'
                        onPointerDown={this.enterMoving.bind(this)}
                        onPointerMove={this.moving.bind(this)}
                        onTouchMove={this.moving.bind(this)}
                        onPointerUp={this.exitMoving.bind(this)}
                        onTouchEnd={this.exitMoving.bind(this)}
                        style={{ overflow: "hidden", marginBottom: "5vh", }}>
                        <div className='drawerBox-bg' style={{
                            width: "100%",
                            position: "relative",
                            backgroundImage: `url(${"./透明.png"})`,
                            pointerEvents: "none",
                        }}>
                            <img className='drawerBox-fakeImg' style={{
                                width: "100%", pointerEvents: "none"
                            }}
                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" />
                            <div className='drawerBox-content' style={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                overflow: "hidden",
                            }}>
                                <img className='drawerBox-image' src={this.props.uploadedImage?.url?.href} style={{
                                    position: "relative",
                                    left: this.state.offsetX + this.state.movingOffsetX,
                                    top: this.state.offsetY + this.state.movingOffsetY,
                                    transform: `scale(${(this.state.scale || 100) / 100})`,
                                }} />
                            </div>
                            <img className='drawerBox-cover' style={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                width: "100%",
                                height: "100%"
                            }} src={this.props.selectedURL?.href} />
                        </div>
                    </div>
                    <div style={{ display: "flex", color: "white", alignItems: "center" }} className='EditorPage-slider-out'>
                        <div><MinusOutlined onClick={() => this.zoomInOut(-1)} /></div>
                        <Slider
                            className='EditorPage-slider'
                            style={{ width: "100%" }}
                            max={100}
                            min={1}
                            value={this.state.scale}
                            tooltip={{ formatter: (value) => `${value}%` }}
                            onChange={(value) => { this.setState({ scale: value }) }} />
                        <div><PlusOutlined onClick={() => this.zoomInOut(1)} /></div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "6vh",
                        marginBottom: "6vh",
                    }}>
                        <BButtion type={"danger"} onClick={this.props.onBack} >
                            {"取消 Cancel"}
                        </BButtion>
                        <BButtion onClick={this.conform.bind(this)} hoverChange>
                            {"确认 OK"}
                        </BButtion>

                    </div>
                </div>
                {this.props.children}
            </div>

        </Background>
    }
    componentDidMount(): void {
        this.checkState()

    }
    componentDidUpdate(): void {
        this.checkState()
    }
    async checkState() {
        if (this.props.uploadedImage && this.drawerBoxRef.current && !this.state.scale) {
            const image = this.props.uploadedImage
            const box = this.drawerBoxRef.current
            const drawerWidth = box.offsetWidth
            const imageWidth = image.width

            const scale = drawerWidth / imageWidth * 100
            this.setState({ scale })
        }
    }
    async conform() {
        setTimeout(async () => {
            if (this.drawerBoxRef.current) {
                const box = this.drawerBoxRef.current
                const canvas = await html2canvas(box, {
                    allowTaint: true,
                    logging: true,
                    useCORS: true,
                })
                const b64Url = canvas.toDataURL('image/png')
                const url = new URL(b64Url)
                const data2 = await ResFetch2URL(url)
                this.props.onDone({
                    blobURL: data2.blobUrl,
                    b64: b64Url
                })
            }
        }, 400);
    }
    zoomInOut(size: number) {
        let next = (this.state.scale || 50) + (size * 5)
        if (next < 0) {
            next = 0
        }
        if (next > 100) {
            next = 100
        }
        this.setState({
            scale: next
        })
    }
    enterMoving(event: React.PointerEvent<HTMLDivElement>) {
        event.stopPropagation()
        event.preventDefault()
        let screenX = event.screenX || 0
        let screenY = event.screenY || 0
        this.setState({
            moving: {
                startScreenX: screenX,
                startScreenY: screenY,
            }
        });
    }
    moving(event: React.PointerEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) {
        let screenX = 0
        let screenY = 0
        event.preventDefault()
        event.stopPropagation()
        if (this.state.moving) {
            if (event.type === "pointermove") {
                const thisEvent = event as React.PointerEvent<HTMLDivElement>
                screenX = thisEvent.screenX
                screenY = thisEvent.screenY
            } else {
                const thisEvent = event as React.TouchEvent<HTMLDivElement>
                screenX = thisEvent.touches[0].screenX
                screenY = thisEvent.touches[0].screenY
            }
            const movedX = (screenX - this.state.moving.startScreenX)
            const movedY = (screenY - this.state.moving.startScreenY)
            this.setState({
                movingOffsetX: movedX,
                movingOffsetY: movedY,
            })
        }
    }
    exitMoving() {
        this.setState({ moving: undefined });
        this.setState({
            offsetX: this.state.offsetX + this.state.movingOffsetX,
            offsetY: this.state.offsetY + this.state.movingOffsetY,
            movingOffsetX: 0,
            movingOffsetY: 0,
        })
    }

}


