
import { BButtion } from '@/component/button';
import { GENERAL_ALLOWED_IMAGE_TYPES } from '@/config/config';
import { getImageInfo } from '@/function/Network';
import {
    LeftOutlined
} from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import React, { PureComponent } from 'react';
import { Background } from '../Background';
import "./index.less";


type ViewPageProps = {
    children: React.ReactNode
    selectedURL?: URL
    finalImage?: {
        blobURL: URL
        b64: string
    }
    onBack: () => void
    onFile: (file: {
        url: URL,
        width: number
        height: number
    }) => void
    onSave: () => void
}
type State = {
    imageURL?: URL
    scale?: number
}
export class ViewPage extends PureComponent<ViewPageProps, State> {
    constructor(props: ViewPageProps) {
        super(props)
        this.state = {
        }
    }

    render() {
        return <Background>
            <div className='ViewPage' style={{ height: 82, display: "flex", alignItems: "center" }}>
                <Button type="text" style={{ color: "white" }} onClick={this.props.onBack}>
                    <LeftOutlined />
                    {"返回 Back"}
                </Button>
            </div>
            <div style={{
                marginLeft: 15,
                width: `calc(100% - ${15 * 2}px)`,
                borderRadius: 20,
                background: `linear-gradient(180deg, #FFFFFF 0%, #E6D9FB 100%)`,
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: 32,

            }}>
                <div style={{
                    width: "100%",
                    position: "relative",
                    backgroundImage: `url(${"./透明.png"})`,
                    marginBottom: 65
                }}>
                    {this.props.finalImage ? <img style={{ width: "100%", }} src={this.props.finalImage.b64} /> : <>
                        <img style={{ width: "100%" }} src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" />
                        <div style={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            width: "100%",
                            height: "100%",
                            backgroundImage: `url(${this.state.imageURL?.href})`,
                            backgroundPositionX: "50%",
                            backgroundPositionY: "50%",
                            backgroundSize: `${this.state.scale || 100}%`,
                        }}
                        />
                        <img style={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            width: "100%",
                            height: "100%",
                        }} src={this.props.selectedURL?.href} />
                    </>}


                </div>


                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Upload
                        accept={GENERAL_ALLOWED_IMAGE_TYPES.join(",")}
                        customRequest={this.onUploaded.bind(this)}>
                        <BButtion type={"danger"} size={"large"}  >
                            {"上传照片"}<br />
                            {"Upload"}
                        </BButtion>
                    </Upload>

                    <BButtion size={"large"} onClick={this.props.onSave} >
                        {"保存头像"}<br />
                        {"Save"}
                    </BButtion>
                </div>

            </div>
            {this.props.children}
        </Background>
    }
    componentDidMount(): void {

    }
    async onUploaded(input: UploadRequestOption) {
        if (input.file instanceof Blob) {
            const objURL = URL.createObjectURL(input.file)
            const url = new URL(objURL)
            const imageInfo = await getImageInfo(url)

            this.props.onFile({
                url,
                ...imageInfo
            })
        }
        return true

    }

}


