
import { User } from '@/user';
import { ConfigProvider, Drawer } from 'antd';
import { PureComponent } from 'react';
import "./global.less";
import "./index.less";
// import "antd/dist/antd.less";
import zhCN from 'antd/es/locale/zh_CN';
import { FirstPage } from './FirstPage';
import { ViewPage } from './ViewPage';
import { EditorPage } from './Editor';
import { SavePage } from './SavePage';
// import  iNoBounce  from 'inobounce';

type State = {
  show?: boolean
  show2?: boolean
  user?: User
  checking?: boolean
  selectedAvatarURL?: URL
  fileURL?: URL
  file?: {
    url: URL,
    width: number
    height: number
  }
  finalImage?: {
    blobURL: URL
    b64: string
  }
  saving?: boolean
}
class Page extends PureComponent<{}, State> {
  constructor({ }) {
    super({})
    this.state = {
    }
  }

  render() {
    return <ConfigProvider locale={zhCN}>
      <div>
        <Drawer title={null}
          className={"scrollFix"}
          destroyOnClose
          closable={false}
          width={"100%"}
          open={true}>
          <FirstPage onInto={(avatarURL) => { this.setState({ selectedAvatarURL: avatarURL }) }}>
            <Drawer title={null}//预览效果
              className={"scrollFix"}
              closable={false}
              width={"100%"}
              open={typeof this.state.selectedAvatarURL !== "undefined"}>
              <ViewPage
                selectedURL={this.state.selectedAvatarURL}
                finalImage={this.state.finalImage}
                onFile={(file) => { this.setState({ file }) }}
                onBack={() => this.setState({ selectedAvatarURL: undefined, finalImage: undefined })}
                onSave={() => this.setState({ saving: true })}>
              </ViewPage>
            </Drawer>
            <Drawer title={null}//编辑图片
              placement={"bottom"}
              closable={false}
              height={"100%"}
              width={"100%"}
              open={typeof this.state.file !== "undefined"}>
              <EditorPage
                uploadedImage={this.state.file}
                selectedURL={this.state.selectedAvatarURL}
                onBack={() => this.setState({ file: undefined })}
                onDone={(image) => this.setState({ file: undefined, finalImage: image })} />
            </Drawer>
            <Drawer title={null}//保存图片
              className={"scrollFix"}
              placement={"bottom"}
              closable={false}
              height={"100%"}
              width={"100%"}
              open={typeof this.state.saving !== "undefined"}>
              <SavePage
                image={this.state.finalImage}
                onBack={() => this.setState({ saving: undefined })} />
            </Drawer>
          </FirstPage>
        </Drawer>

      </div>
    </ConfigProvider>
  }
  componentDidMount(): void {
    document.body.addEventListener('touchmove', function (e) {
      e.preventDefault();
    }, { passive: false });

    this.setDrawer()

  }
  componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<State>, snapshot?: any): void {
    this.setDrawer()
  }
  setDrawer() {
    const doms = document.getElementsByClassName("ant-drawer")
    for (let dom of doms) {
      if (new Set(dom.classList).has("scrollFix")) {
        dom.addEventListener('touchmove', function (event) {
          event.stopImmediatePropagation();
        }, { passive: false });
      }
    }
  }
}

export default Page
