import { getImageInfo } from "@/function/Network";
import { ResFetch2URL } from "@/function/Network";
import React from "react";
import { PureComponent } from "react";


type RBackgroundConfig = { urlPreFix?: string }
export const BackgroundConfig = React.createContext<RBackgroundConfig>({});


export class RBackgroundMobile extends PureComponent<BackgroundProps> {
    constructor(props: BackgroundProps) {
        super(props);
    }
    render() {
        const width = window.innerWidth
        if (!(width < 900)) {
            return ""
        }
        return <Background {...this.props} />
    }


}

export class RBackgroundPC extends PureComponent<BackgroundProps> {
    constructor(props: BackgroundProps) {
        super(props);
    }
    render() {
        const width = window.innerWidth
        if ((width < 900)) {
            return ""
        }
        return <Background {...this.props} />
    }


}




















type BackgroundProps = {
    children?: React.ReactNode
    style?: React.CSSProperties
    url?: string
    shadow?: boolean
}


type BackgroundState = {
}
export class Background extends PureComponent<BackgroundProps, BackgroundState> {
    static contextType = BackgroundConfig
    URL?: URL;

    constructor(props: BackgroundProps) {
        super(props);
        this.state = {
        };
    }
    render() {

        return <BackgroundConfig.Consumer>
            {CONFIG => {
                return <div className="Background" style={{
                    position: "relative",
                    width: "100%",
                    minHeight: "100%",
                    backgroundColor: "#390F9A",
                    display: "flex",
                    justifyContent: "center",
                    overflow: "hidden",
                    ...this.props.style
                }}
                >
                    <div className="Background-main" style={{
                        width: "100%",
                        maxWidth: 600,
                        position: "relative"
                    }}>
                        <div className="Background-top" style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            backgroundImage: `url(${`./顶部花纹.png`})`,
                            backgroundPosition: "0px -70px",
                            backgroundSize: "100%",
                            backgroundRepeat: "no-repeat",
                            zIndex: 1,
                        }} />
                        <div className="Background-bottom" style={{
                            position: "absolute",
                            bottom: 0,
                            height: "100%",
                            width: "100%",
                            backgroundImage: `url(${`./底部花纹-高反差.png`})`,
                            backgroundPosition: "bottom",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            zIndex: 3,
                        }} />
                        <div className="Background-bottom-logo" style={{
                            position: "absolute",
                            bottom: 0,
                            height: 155,
                            width: "100%",
                            backgroundImage: `url(${`./底部LOGO.png`})`,
                            backgroundPosition: "center",
                            backgroundSize: "50%",
                            backgroundRepeat: "no-repeat",
                            zIndex: 3,
                        }} />
                        <div className="Background-filter" style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            background: `radial-gradient(134.8% 134.8% at -39.65% -10.07%, rgba(91, 12, 12, 0.0001) 0%, rgba(87, 6, 140, 0.0001) 52.92%, #510085 100%)`,
                            zIndex: 2,
                        }} />
                        <div className="Background-filter" style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            background: `linear-gradient(180deg, #57068C 0%, #490079 156.78%)`,
                            zIndex: 2,
                            // mixBlendMode: "normal",
                            opacity: 0.6,

                        }} />
                        <div className="Background-filter" style={{
                            display: this.props.shadow ? "block" : "none",
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            background: `rgb(22 0 42 / 80%)`,
                            zIndex: 3,
                        }} />
                        <div className="Background-content" style={{ position: "relative", zIndex: 5, paddingBottom: 155 }} >
                            {this.props.children}
                        </div>
                    </div>
                </div >
            }}
        </BackgroundConfig.Consumer>
    }
    async componentDidMount() {

    }




}

