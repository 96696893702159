
import React, { PureComponent } from 'react';
import { Background } from '../Background';
import { Selector } from '../Selector';
type FirstPageProps = {
    children: React.ReactNode
    onInto: (avatarURL: URL) => void
}
type State = {
}
export class FirstPage extends PureComponent<FirstPageProps, State> {
    constructor(props: FirstPageProps) {
        super(props)
        this.state = {
        }
    }

    render() {
        return <Background>
            <img src="./首页横幅.png" style={{ maxWidth: "100%" }} />
            <div style={{
                marginLeft: 15,
                width: `calc(100% - ${15 * 2}px)`,
                borderRadius: 20,
                background: `linear-gradient(90deg, #F7E9FF 6.15%, #EDFFEE 30.93%, #FEDDFF 58.97%, #FBF1FF 82.78%, #F9F3FF 99.83%)`,
                overflow: "hidden"
            }}>
                <div style={{ height: 58, lineHeight: "58px", fontSize: 18, textAlign: "center", color: "#4300A2", fontWeight: 700 }}>
                    {"选择相框"}
                    <div style={{ display: "inline-block",width:16 }} />
                    {"Select a frame"}
                </div>
                <div style={{
                    width: `100%`,
                    borderTopLeftRadius: 32,
                    borderTopRightRadius: 32,
                    minHeight: 600,
                    background: `linear-gradient(180deg, #FFFFFF 0%, #E6D9FB 100%)`
                }}>
                    <Selector onClick={this.props.onInto} />
                </div>
            </div>
            {this.props.children}
        </Background>
    }
    componentDidMount(): void {

    }

}


