
import { Button, Slider, Upload } from 'antd';
import React, { PureComponent } from 'react';
import { Background } from '../Background';
import { Selector } from '../Selector';
import ImgCrop from 'antd-img-crop';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import {
    HomeOutlined,
    LeftOutlined,
    SettingFilled,
    SmileOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import { BButtion } from '@/component/button';
import { getImageInfo } from '@/function/Network';


type SavePageProps = {
    image?: {
        blobURL: URL
        b64: string
    }
    onBack: () => void
}
type State = {
}
export class SavePage extends PureComponent<SavePageProps, State> {
    constructor(props: SavePageProps) {
        super(props)
        this.state = {
        }
    }

    render() {
        return <Background shadow>
            <div style={{ height: 60, display: "flex", alignItems: "center" }} />
            <div style={{
                marginLeft: 15,
                width: `calc(100% - ${15 * 2}px)`,
                borderRadius: 20,
                background: `linear-gradient(180deg, #FFFFFF 0%, #E6D9FB 100%)`,
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: 32,
            }}>
                <div style={{ overflow: "hidden", }}>
                    <div style={{
                        width: "100%",
                        position: "relative",
                        backgroundImage: `url(${"./透明.png"})`,
                        marginBottom: 28,
                    }}>
                        <img style={{ width: "100%" }} src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" />
                        {this.isWechat() ? <>
                            <img style={{ width: "100%", position: "absolute", left: 0 }} src={this.props.image?.b64} />
                        </> : <>
                            <img style={{ width: "100%", position: "absolute", left: 0 }} src={this.props.image?.blobURL.href} />
                        </>}
                    </div>
                </div>

                <div style={{ textAlign: "center", fontSize: 14,fontWeight:600 }}>
                    <div style={{ color: "#333333" }}>{"长按保存图片"}</div>
                    <div style={{ color: "#888888" }}>{"Press and hold to save the photo"}</div>
                </div>
            </div>
            <div style={{
                display: "flex",
                justifyContent: "center",
                margin: 15,
                marginTop: 50,
            }}>
                <BButtion onClick={this.props.onBack} style={{ width: "100%" }} >
                    {"返回 Back"}
                </BButtion>
            </div>

        </Background>
    }
    isWechat() {
        return true
        return /MicroMessenger/i.test(window.navigator.userAgent);
    }
}


